import "./button.scss";
import { getClassName } from "./utils/getClassName";
import { ButtonProps } from "./types";
import React from "react";

const Button: React.FC<ButtonProps> = ({ children, className, primary, secondary, ...rest }) => (
  <button className={getClassName({ className, primary, secondary })} {...rest}>
    {children}
  </button>
);

export default Button;
