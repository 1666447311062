import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Button from "../components/button";
import Layout from "../components/layout";
import Section from "../components/section";
import Seo from "../components/seo";

const About: React.FC<PageProps> = () => (
  <Layout color="gray">
    <Seo title="About" />
    <Section width="md">
      <div className="flex flex-col-mob__breakpoint-main justify-between">
        <StaticImage
          // height={600}
          src="../images/amy-sitting-yoga.jpg"
          alt="about page amy photo"
          className="m-b_sm max-width_sm"
        />
        <div className="max-width_sm">
          <h4>HI, I'M BB AMY</h4>
          <h6 className="txt-light">Maybe you want to launch a business.</h6>
          <h6 className="txt-light">Maybe you want to turn a hobby into something more.</h6>
          <p>
            Don’t worry about sounding professional. Sound like you. There are over 1.5 billion websites out there, but
            your story is what’s going to separate this one from the rest. If you read the words back and don’t hear
            your own voice in your head, that’s a good sign you still have more work to do.
          </p>
          <Button>Work with me</Button>
        </div>
      </div>
    </Section>
    <Section width="md">
      <hr />
      <div className="flex flex-col-mob__breakpoint-main justify-between">
        <h4>MY APPROACH</h4>
        <div className="max-width_sm max-width-mob-none__breakpoint-main">
          <p className="m-l_xsm">
            It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into
            something more. Or maybe you have a creative project to share with the world. Whatever it is, the way you
            tell your story online can make all the difference.{" "}
          </p>
          <div className="flex flex-wrap-mob__breakpoint-main">
            <div className="m-xsm">
              <h6 className="txt-light">DREAM IT</h6>
              <p>
                Don’t worry about sounding professional. Sound like you. There are over 1.5 billion websites out there,
                but your story is what’s going to separate this one from the rest. If you read the words back and don’t
                hear your own voice in your head, that’s a good sign you still have more work to do.
              </p>
            </div>
            <div className="m-xsm">
              <h6 className="txt-light">BUILD IT</h6>
              <p>
                Be clear, be confident and don’t overthink it. The beauty of your story is that it’s going to continue
                to evolve and your site can evolve with it. Your goal should be to make it feel right for right now.
                Later will take care of itself. It always does.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  </Layout>
);

export default About;
