import { ApiPath } from "../utils/getApiUrl";
import { ContactType } from "../types/contactType";
import { PageProps } from "gatsby";
import Button from "../components/button";
import Checkbox from "../components/checkbox";
import Input from "../components/input";
import Layout from "../components/layout";
import Modal from "../components/modal";
import React, { useState } from "react";
import Section from "../components/section";
import Seo from "../components/seo";
import TextArea from "../components/textarea";

class ContactForm {
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  subject: string = "";
  message: string = "";
  types: ContactType[] = [];
}

type ContactFormKey = keyof ContactForm;

const Contact: React.FC<PageProps> = () => {
  const [formState, setFormState] = useState<ContactForm>(new ContactForm());
  const [showModal, setShowModal] = useState<boolean>(false);
  const [response, setResponse] = useState<Response>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // create params & fire request
      const params = new URLSearchParams({
        ...formState,
        types: formState.types.join(", "),
      });
      const resp = await fetch(ApiPath.ContactEmail + `?${params.toString()}`);

      // handle response
      setResponse(resp);
      setShowModal(true);
      if (!resp.ok) throw resp;

      // log response and reset form
      const respJson = await resp.json();
      console.log(respJson);
      setFormState(new ContactForm());
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleTypesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as ContactType;
    setFormState({
      ...formState,
      types: formState.types.includes(value) ? formState.types.filter((x) => x !== value) : [...formState.types, value],
    });
  };

  return (
    <Layout color="gray">
      <Seo title="Contact" />
      <Section width="md">
        <h4>CONTACT ME</h4>
        <div className="flex justify-between flex-col-mob__breakpoint-main">
          <p className="max-width_sm m-t_xsm">
            For inquires about event or press opportunities, please feel free to reach out here. If you’re interested in
            1:1 coaching, you can go ahead and schedule your free consultation now.
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <div className="flex flex-col-mob__breakpoint-main">
              <div className="flex-1 m-xsm m-l-r_none__breakpoint-main">
                <Input<ContactFormKey>
                  name="firstName"
                  label="First Name"
                  className="width-100"
                  value={formState.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex-1 m-xsm m-l-r_none__breakpoint-main">
                <Input<ContactFormKey>
                  name="lastName"
                  label="Last Name"
                  className="width-100"
                  value={formState.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="m-xsm m-l-r_none__breakpoint-main">
              <Input<ContactFormKey>
                name="email"
                label="Email"
                className="width-100"
                value={formState.email}
                onChange={handleChange}
              />
            </div>
            <div className="m-xsm m-l-r_none__breakpoint-main">
              <Input<ContactFormKey>
                name="subject"
                label="Subject"
                className="width-100"
                value={formState.subject}
                onChange={handleChange}
              />
            </div>
            <div className="m-xsm m-l-r_none__breakpoint-main">
              <TextArea<ContactFormKey>
                name="message"
                label="Message"
                className="width-100"
                value={formState.message}
                onChange={handleChange}
              />
            </div>
            <div className="m-xsm m-l-r_none__breakpoint-main">
              <div className="m-b_xsm">Inquiring About</div>
              <div className="m-b_xsm">
                <Checkbox<ContactFormKey, ContactType>
                  name="types"
                  label="Yoga"
                  checked={formState.types.includes("Yoga")}
                  value="Yoga"
                  onChange={handleTypesChange}
                />
              </div>
              <div className="m-b_xsm">
                <Checkbox<ContactFormKey, ContactType>
                  name="types"
                  label="Dance Movement Therapy"
                  checked={formState.types.includes("Dance Movement Therapy")}
                  value="Dance Movement Therapy"
                  onChange={handleTypesChange}
                />
              </div>
              <div className="m-b_xsm">
                <Checkbox<ContactFormKey, ContactType>
                  name="types"
                  label="1:1 Coaching"
                  checked={formState.types.includes("1:1 Coaching")}
                  value="1:1 Coaching"
                  onChange={handleTypesChange}
                />
              </div>
            </div>
            <Button className="m-xsm m-l-r_none__breakpoint-main" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </Section>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setResponse(undefined);
        }}>
        {response?.ok && (
          <>
            <h5>THANKS FOR REACHING OUT LOVE!</h5>
            <p>Will reach out to you soon with the info that your heart desires :)</p>
          </>
        )}
        {response?.ok === false && (
          <>
            <h5>Oops! Something went wrong on our end.</h5>
            <p>
              Sorry for the inconvenience. We're working on a resolution. Please try again and if the issue persists,
              email directly me at a.e.motson@gmail.com
            </p>
          </>
        )}
      </Modal>
    </Layout>
  );
};

export default Contact;
