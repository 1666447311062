// import { StaticImage } from "gatsby-plugin-image";
// import Section from "../section";
import "./styles.scss";
import { IStaticImageProps } from "gatsby-plugin-image/dist/src/components/static-image.server";
import React from "react";

const HeroPhotoSection: React.FC<IStaticImageProps> = ({ children, ...imgProps }) => {
  return (
    <div className="hero-photo-container">
      {children}
      {/* <div className="hero-photo-background">
        <StaticImage   
            {...imgProps}
        />
      </div>
      <Section>
        {children}
      </Section>       */}
    </div>
  );
};
export default HeroPhotoSection;
