import "./section.scss";
import { ClassName } from "../../types/utils/className";
import * as React from "react";

type SectionWidth = "sm" | "md" | "lg";

interface Props extends ClassName {
  width?: SectionWidth;
}

const Section: React.FC<Props> = ({ children, width = "sm", className }) => (
  <section className={`section section-maxWidth_${width} ${className || ""}`}>{children}</section>
);

export default Section;
