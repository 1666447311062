// import "./styles.modules.scss"
import { TextAreaProps } from "./types";
import React from "react";

function TextArea<K>(props: TextAreaProps<K>) {
  return (
    <div>
      <label>{props.label}</label>
      <textarea {...props} />
    </div>
  );
}

export default TextArea;
