import { ButtonProps } from "../types";
export function getClassName(props: ButtonProps): string {
  let className: string[] = ["btn"];

  if (props.className) className.push(props.className);
  if (props.primary) className.push("btn-primary");
  if (props.secondary) className.push("btn-secondary");

  return className.join(" ");
}
