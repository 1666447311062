import "./styles.modules.scss";
import { InputProps } from "./types";
import React from "react";

function Input<K>(props: InputProps<K>) {
  return (
    <div>
      <label>{props.label}</label>
      <input {...props} />
    </div>
  );
}

export default Input;
