// NOTES
//  - would love to configure the api url based off the env, however using env variables for dev, staging, prod
//      requires a more complex netlify.toml build config file which I'm not ready to mess with just yet.

const baseLambdaPath = "/.netlify/functions";

export const ApiPath = {
  ContactEmail: getApiUrl() + baseLambdaPath + "/emailHandler",
} as const;

export function getApiUrl(): string {
  // const env = process.env?.NODE_ENV?.toLowerCase();
  const host = typeof window !== "undefined" && window?.location?.hostname;
  if (!host) return "";
  if (host.includes("staging")) {
    return "https://staging.movethroughct.com";
  } else if (host.includes("movethroughct.com")) {
    return "https://movethroughct.com";
  }
  return "http://localhost:8888";
}
