// import Button from "../button";
import "./styles.scss";
import { Hamburger } from "../hamburger";
import { Link } from "gatsby";
import { setBodyOverflow } from "../../utils/setBodyOverflow";
import * as React from "react";
import Button from "../button";
// import InstagramIcon from "../../svgs/instagram.inline.svg"
import PropTypes from "prop-types";
import Section from "../section/index";

const HeaderMobile = ({ siteTitle, color }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setBodyOverflow(open);
  }, [open]);

  return (
    <div
      className={`width-100 headerMobileContainer headerMobileContainer_${color} ${open ? "menuOpen" : "menuClosed"}`}>
      <Section width="md" className="flex justify-between items-center headerMobile">
        <Link to="/">
          <h5 className="m-b_none m-r_sm">{siteTitle}</h5>
        </Link>
        <Hamburger open={open} handleOpen={() => setOpen(true)} handleClose={() => setOpen(false)} />
      </Section>
      {open && (
        <Section width="md">
          <div className="flex flex-col mobile-header-content_margin">
            <Link className="m-b_sm" to="/about">
              About
            </Link>
            <Link className="m-b_sm" to="/offerings">
              Offerings
            </Link>
            <Link className="m-b_sm" to="/contact">
              <Button>Contact</Button>
            </Link>
          </div>
        </Section>
      )}
    </div>
  );
};

const Header = ({ siteTitle, color, headerClassName }) => (
  <header className={`header flex m-b_sm ${headerClassName || ""}`}>
    <Section width="md" className="headerDesktop">
      <div className="flex justify-between items-center">
        <div className="header-block_left">
          <Link to="/">
            <h4 className="m-b_none">{siteTitle}</h4>
          </Link>
        </div>
        <div className="flex items-center header-block_right">
          <Link to="/about" className="m-r_sm">
            About
          </Link>
          <Link to="/offerings" className="m-r_sm">
            Offerings
          </Link>
          <Link to="/contact">
            <Button>Contact</Button>
          </Link>
        </div>
      </div>
    </Section>
    <HeaderMobile siteTitle={siteTitle} color={color} />
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  color: PropTypes.string,
  headerClassName: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
