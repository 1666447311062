import { Link, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Button from "../components/button";
import Layout from "../components/layout";
import Section from "../components/section";
import Seo from "../components/seo";

const Offerings: React.FC<PageProps> = () => (
  <Layout color="beige">
    <Seo title="Offerings" />
    <Section width="md">
      <h4 className="txt-right m-b_none">Yoga</h4>
      <h4 className="txt-right">Classes</h4>
      <div className="flex justify-between flex-col-mob__breakpoint-main">
        <div>
          <a href="https://afterglowstudiohartford.com/scheduling" target="_blank" rel="noreferrer">
            <h4>Afterglow Yoga Studio</h4>
          </a>
          <h6 className="txt-light">Wednesdays & Fridays 1-3pm</h6>
          <hr />
          <a href="https://challengingminds.net/classes/" target="_blank" rel="noreferrer">
            <h4>Challenging Minds</h4>
          </a>
          <h6 className="txt-light">Tuesdays & Thursdays 9-11am</h6>
          <hr />
          <h4>Personal Sessions</h4>
          <h6 className="txt-light">Call to schedule!</h6>
          <hr />
        </div>
        <div className="max-width_sm">
          <p className="txt-right">
            Sign up to attend my live webinars, which explore different strategies for better connecting the mind &
            body.
          </p>
          <StaticImage
            src="../images/amy-sitting-yoga.jpg"
            alt="about page amy photo"
            className="m-b_sm max-width_sm"
          />
        </div>
      </div>
    </Section>
    <Section width="md">
      <hr />
      <h4>Dance Movement Therapy</h4>
      <div className="flex justify-between flex-col-mob__breakpoint-main">
        <div>
          <p className="max-width_sm">Get a personalized plan and regular check-ins to help you reach your goals.</p>
          <StaticImage
            src="../images/amy-sitting-yoga.jpg"
            alt="about page amy photo"
            className="m-b_sm max-width_sm"
          />
        </div>
        <div className="max-width_sm">
          <h5>WEEKLY SESSIONS</h5>
          <p>
            If you’re looking for something completely integrated and tailored to your specific needs, 1:1 wellness
            coaching might be the right step for you. Learn what you can expect in a session or book a free consultation
            today.{" "}
          </p>
          <Link to="/contact">
            <Button>Contact Me</Button>
          </Link>
        </div>
      </div>
    </Section>
    <Section width="md">
      <hr />
      <h4 className="txt-right">1:1 Coaching</h4>
      <div className="flex justify-between flex-col-mob__breakpoint-main">
        <div className="max-width_sm">
          <h5>WEEKLY SESSIONS</h5>
          <p>
            If you’re looking for something completely integrated and tailored to your specific needs, 1:1 wellness
            coaching might be the right step for you. Learn what you can expect in a session or book a free consultation
            today.{" "}
          </p>
          <Link to="/contact">
            <Button>Contact Me</Button>
          </Link>
        </div>
        <div>
          <p className="txt-right max-width_sm">
            Get a personalized plan and regular check-ins to help you reach your goals.
          </p>
          <StaticImage
            src="../images/amy-sitting-yoga.jpg"
            alt="about page amy photo"
            className="m-b_sm max-width_sm"
          />
        </div>
      </div>
    </Section>
  </Layout>
);

export default Offerings;
