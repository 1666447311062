import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Button from "../components/button";
import HeroPhotoSection from "../components/heroPhotoSection";
// import HeroPhotoSection from "../components/heroPhotoSection";
import Layout from "../components/layout";
import Section from "../components/section/index";
import Seo from "../components/seo";

const IndexPage = () => (
  <Layout color="green" className="txt-white" headerClassName="hero-header">
    <Seo title="Home" />
    {/* <HeroPhotoSection
       className="width-100 height-100"    
       height={800}  
       src="../images/kike-vega-F2qh3yjz6Jk-unsplash.jpg"                
       alt="epic yoga lady"
    >
      <div className="home-heroHeaders txt-center">
        <h6 className="txt-light">YOUR CERTIFIED GUIDE TO</h6>
        <h1>MINDFUL MOVEMENT AS A WAY OF LIFE</h1>
      </div>
    </HeroPhotoSection> */}
    <HeroPhotoSection>
      <div className="hero-photo-background">
        <StaticImage
          className="width-100 height-100"
          // height={800}
          src="../images/kike-vega-F2qh3yjz6Jk-unsplash.jpg"
          alt="lady on chair"
        />
      </div>
      <Section>
        <div className="hero-photo-text-padding">
          <div className="home-heroHeaders txt-center">
            <h6 className="txt-light">YOUR CERTIFIED GUIDE TO</h6>
            <h1>MINDFUL MOVEMENT AS A WAY OF LIFE</h1>
          </div>
        </div>
      </Section>
    </HeroPhotoSection>

    <Section width="md">
      <h4 className="txt-center">Work with Amy</h4>
      <div className="flex flex-wrap justify-around width-100">
        <div className="flex flex-col items-center txt-center m-xsm m-b_sm max-width_xsm">
          <h6 className="txt-light m-b_xsm">1:1 COACHING</h6>
          <p className="m-b_xsm">
            The ideal choice if you want a personalized plan that is tailored to your goals and lifestyle.
          </p>
          <Link to="/offerings">
            <Button>Learn More</Button>
          </Link>
        </div>
        <div className="flex flex-col items-center txt-center m-xsm m-b_sm max-width_xsm">
          <h6 className="txt-light m-b_xsm">ONLINE COURSES</h6>
          <p className="m-b_xsm">
            Explore wellness principles on your own schedule through a variety of online courses.
          </p>
          <Link to="/offerings">
            <Button>Learn More</Button>
          </Link>
        </div>
        <div className="flex flex-col items-center txt-center m-xsm m-b_sm max-width_xsm">
          <h6 className="txt-light m-b_xsm">LIVE EVENTS</h6>
          <p className="m-b_xsm">
            Begin your wellness journey with full access to our beginner-friendly live webinars and events.{" "}
          </p>
          <Link to="/offerings">
            <Button>Learn More</Button>
          </Link>
        </div>
      </div>
    </Section>
    <Section width="md">
      <hr />
      <div className="flex flex-col-mob__breakpoint-main justify-between">
        <div className="m-auto">
          <h4 className="m-b_none">MOVEMENT</h4>
          <h4>GUIDANCE</h4>
          <StaticImage
            className="m-b_sm max-width_sm"
            src="../images/Afterglow-44.JPEG"
            // width={400}
            alt="cool girl"
          />
        </div>
        <div className="max-width_sm max-width-mob-none__breakpoint-main">
          <h4 className="m-l_xsm">Improving your relationship with your body</h4>
          <div className="flex flex-wrap-mob__breakpoint-main">
            <div className="m-xsm">
              <h6 className="txt-light">TRY SOMETHING NEW</h6>
              <p>
                Begin exploring your relationship with food by trying any of my free recipes and meal plans. Healthy
                food should also be delicious.
              </p>
              <p>
                <u>Explore seasonal recipes</u>
              </p>
            </div>
            <div className="m-xsm">
              <h6 className="txt-light">TARGET YOUR GOALS</h6>
              <p>
                If allergies, illness, or other aversions have complicated your relationship with food, a personalized
                plan might be right for you.{" "}
              </p>
              <p>
                <u>Explore seasonal recipes</u>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
    <Section width="md">
      <hr />
      <div className="flex flex-col-rev-mob__breakpoint-main justify-between">
        <div className="max-width_sm">
          <h4 className="m-l_xsm">Creating a plan that works for you</h4>
          <div className="flex flex-wrap-mob__breakpoint-main max-width_sm max-width-mob-none__breakpoint-main">
            <div className="m-xsm">
              <h6 className="txt-light">TRY SOMETHING NEW</h6>
              <p>
                Through my personalized coaching, I’ll work alongside you to develop an integrated plan to achieve full
                body wellness—often combining the practices of nutrition, exercise, acupuncture, meditation, herbal
                remedies, and more.{" "}
              </p>
              <Button>Learn More</Button>
            </div>
          </div>
        </div>
        <div className="m-auto">
          <h4 className="m-b_none txt-right">COOL</h4>
          <h4 className="txt-right">GIRL</h4>
          <StaticImage
            className="m-b_sm max-width_sm"
            src="../images/wall 2.jpg"
            // width={400}
            alt="cool girl"
          />
        </div>
      </div>
    </Section>
    <Section width="md">
      <hr />
      <h4 className="txt-center">LOVE TO SEE IT</h4>
      <div
        className="grid"
        style={{
          gap: "1.75rem",
          gridTemplateColumns: "repeat(auto-fit, minmax(275px, 1fr))",
        }}>
        <StaticImage src="../images/bourbon ad with babe.jpg" alt="" />
        <StaticImage src="../images/maple syrup product.jpg" alt="" />
        <StaticImage src="../images/bourbon salute.jpg" alt="" />
        <StaticImage src="../images/fire pit.jpg" alt="" />
        <StaticImage src="../images/wine baby 1.jpg" alt="" />
        <StaticImage src="../images/syrup gushing.jpg" alt="" />
      </div>
    </Section>
  </Layout>
);

export default IndexPage;
