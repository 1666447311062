import "./styles.modules.scss";
import { CheckboxProps } from "./types";
import React from "react";

function Checkbox<K, V>({ ...props }: CheckboxProps<K, V>) {
  return (
    <label className="checkbox-container">
      {props.label}
      <input type="checkbox" {...props} />
      <span className="checkmark" />
    </label>
  );
}

export default Checkbox;
