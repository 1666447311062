import "../../styles/index.scss";
import "./layout.css";
import { useStaticQuery, graphql } from "gatsby";
import * as layoutColors from "./layoutColor.module.scss";
import * as React from "react";
import Header from "../header";
import PropTypes from "prop-types";
import Section from "../section";

const layoutColorMap = {
  green: layoutColors.layoutBackground_green,
  gray: layoutColors.layoutBackground_gray,
  beige: layoutColors.layoutBackground_beige,
};

const Layout = ({ children, color, className, headerClassName }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  let containerClassName = "";
  if (color) containerClassName += layoutColorMap[color];
  if (className) containerClassName += ` ${className}`;

  return (
    <div className={containerClassName}>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} color={color} headerClassName={headerClassName} />
      <div className="m-auto">
        <main>{children}</main>
        <Section>
          <footer>© {new Date().getFullYear()} Move Through CT</footer>
        </Section>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  headerClassName: PropTypes.string,
};

export default Layout;
