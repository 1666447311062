import "./styles.modules.scss";
import { createPortal } from "react-dom";
import { ModalProps } from "./types";
import { nodeContainsClick } from "../../utils/nodeContainsClick";
import { setBodyOverflow } from "../../utils/setBodyOverflow";
import React from "react";

class Modal extends React.Component<ModalProps> {
  ref = React.createRef<HTMLDivElement>();

  componentDidMount() {
    // attach handlers
    document.addEventListener("mousedown", this.onMousedown);
    document.addEventListener("keydown", this.onKeydown);
  }

  onKeydown = (e: KeyboardEvent) => {
    const { open, onClose } = this.props;
    // close modal on esc
    if (e.key === "Escape" && open) {
      onClose();
    }
  };

  onMousedown = (e: MouseEvent) => {
    //const ref = this.ref.current;
    const x = document.getElementById("modal-ui");

    if (this.props.open && !nodeContainsClick(x, e)) {
      console.log("closing modal in mousedown event");
      this.props.onClose();
    }
  };

  componentDidUpdate(prevProps: ModalProps) {
    const { open } = this.props;

    // props change to open
    if (!prevProps.open && open) {
      setBodyOverflow("hidden");
    }

    // props change to close
    if (prevProps.open && !open) {
      setBodyOverflow("auto");
    }
  }

  componentWillUnmount() {
    // remove event listeners!
    document.removeEventListener("mousedown", this.onMousedown);
    document.removeEventListener("keydown", this.onKeydown);
  }

  render() {
    const { children, open } = this.props;

    // NOTES
    //- when returning out early here, if using the ref approach, the ref never apparently gets set.
    //  Hence using the getElementById in onMouseDown
    if (!open) return null;

    return createPortal(
      <div className={`modal ${open ? "open" : "closed"} flex justify-center items-center`}>
        <div className="modal-content" ref={this.ref} id="modal-ui">
          <div className="icon-close-black" onClick={this.props.onClose} />
          {children}
        </div>
      </div>,
      document.body,
    );
  }
}

export default Modal;
