// import { Link } from "gatsby"
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
  </Layout>
);

export default SecondPage;
